import React, { useState } from 'react';
import {
  AppBar,
  makeStyles,
  Toolbar,
  Badge,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { appProfileSelector, localeSelector } from 'redux/selectors';
import { FormattedMessage } from 'react-intl';
import { changeLocale } from 'redux/actions/language';
import useRouter from 'utils/useRouter';
import { logout } from 'redux/actions/auth';
import TabImage from 'assets/images/tabimage.png';
import LeftDecoration from 'assets/images/decoration_left.png';
import RightDecoration from 'assets/images/decoration_right.png';
import { ReactComponent as IconCart } from 'assets/svgs/product_cart.svg';
import { ReactComponent as IconChat } from 'assets/svgs/chat.svg';
import { drawerWidth, drawerCloseWidth } from '../NavBar'

export const topbar_height = 80

const useStyles = makeStyles(theme => ({
  appBar: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    height: topbar_height,
    minHeight: 'auto',
    boxShadow: 'none',
    borderBottom: '1px solid #D3DAF7',
    transition: 'all .5s ease'
  },
  toolbar: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: '2%',
    paddingRight: '2%',
    backgroundColor: '#F2F5FB',
    minHeight: 'unset !important'
  },
  logo: {
    height: 30,
  },
  divider: {
    width: 1,
    backgroundColor: '#868aa8',
    height: '40%',
    margin: '0 5px',
  },
  name: { whiteSpace: 'nowrap', color: '#868aa8' },
  middle: {
    whiteSpace: 'nowrap',
    color: '#868aa8',
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(1, 0)
  },
  middleLogo: {
    height: 30,
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: 14,
    backgroundColor: theme.palette.text.primary,
  },
  lng: {
    width: 32,
    height: 32,
    fontSize: 14,
    backgroundColor: theme.palette.app.primary,
  },
  langBtn: {
    color: 'white',
    border: `2px solid white`,
    borderRadius: 10,
    padding: `2px 5px`,
    marginBottom: 6,
    minWidth: 'unset',
    fontSize: 15,
    fontWeight: 'bold'
  },
  clientBtn: {
    borderRadius: theme.spacing(1),
    padding: '6px 20px',
    marginRight: theme.spacing(2)
  },
  clientBtnLabel: {
    textTransform: 'none'
  },
  indicator: {
    width: 65,
    height: 5,
    backgroundColor: '#6CB570',
    borderRadius: 10,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 4
  },
  tabButton: {
    backgroundColor: 'white',
    color: theme.palette.app.primary,
    borderRadius: '0px !important',
    marginLeft: 35,
    marginRight: 35,
    transitionDuration: '0s !important',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  InactivetabButton: {
    color: 'white',
    borderRadius: '0px !important',
    marginLeft: 35,
    marginRight: 35,
    transitionDuration: '0s !important',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  logoleft: {
    position: 'absolute',
    top: 0,
    height: '100%',
    right: '100%'
  },
  logoright: {
    position: 'absolute',
    top: 0,
    height: '100%',
    left: '100%'
  },
  logomiddle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0
  },
  iconbutton: {
    color: theme.palette.text.primary,
    margin: theme.spacing(0, 2),
    fontWeight: '600',
    textTransform: 'none'
  },
  btnIcon: {
    fill: theme.palette.app.primary,
    width: 20,
    height: 20
  },
  badge: {
    backgroundColor: theme.palette.app.primary,
    color: 'white',
    right: 5,
    fontSize: 8,
    top: 17,
    width: 16,
    height: 16,
    minWidth: 16
  },
  iconWrap: {
    width: 35,
    height: 35,
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  langRoot: {
    zIndex: "10001 !important"
  }
}));

const APAVEIMAGE = require('assets/images/apave.png');
const APAVEIMAGELIGHT = require('assets/images/apave-logo.png');
const APAVELOGOIMAGE = require('assets/images/logoAvertinOne.png');
const LOCALEIMAGES = {
  en: require('assets/images/en.png'),
  fr: require('assets/images/fr.png'),
};

const TopBar = ({ openDrawer }) => {
  const classes = useStyles();
  const [barState, setBarState] = useState({
    anchorEl: null,
    lng: null,
  });

  const [tabIndex, setTabIndex] = useState(0);

  const { firstName, lastName } = useSelector(appProfileSelector);
  const locale = useSelector(localeSelector);
  const dispatch = useDispatch();
  const router = useRouter();
  const handleMenu = key => event => {
    setBarState(barState => ({
      ...barState,
      [key]: event.currentTarget,
    }));
  };

  const handleMenuClose = key => () => {
    setBarState(barState => ({
      ...barState,
      [key]: null,
    }));
  };

  const handleLocaleChange = lng => () => {
    setBarState(barState => ({
      ...barState,
      lng: null,
    }));
    dispatch(changeLocale(lng));
  };

  const onSignOut = () => {
    handleMenuClose('anchorEl')();
    dispatch(logout());
  };

  const onProfile = () => {
    handleMenuClose('anchorEl')();
    router.history.push('/profile');
  };

  const open = Boolean(barState.anchorEl);
  const openLng = Boolean(barState.lng);

  const LeftImage = <img src={LeftDecoration} alt="" className={classes.logoleft} />
  const RightImage = <img src={RightDecoration} alt=" " className={classes.logoright} />
  return (
    <AppBar
      elevation={0}
      position="fixed"
      color="default"
      className={classes.appBar}
      style={!openDrawer ? {
        marginLeft: drawerCloseWidth,
        width: `calc(100% - ${drawerCloseWidth}px)`
      } : {}}
    >
      <Toolbar className={classes.toolbar}>
        {/* <Button className={tabIndex == 0 ? classes.tabButton : classes.InactivetabButton} onClick={() => setTabIndex(0)} disableTouchRipple>
          {tabIndex == 0 && LeftImage}
          {tabIndex == 0 && RightImage}
          <img src={tabIndex == 0 ? APAVEIMAGE : APAVEIMAGELIGHT} alt="apave" className={classes.logo} />
        </Button> */}
        {/* <Button className={tabIndex == 1 ? classes.tabButton : classes.InactivetabButton} onClick={() => setTabIndex(1)} disableTouchRipple>
          {tabIndex == 1 && LeftImage}
          {tabIndex == 1 && RightImage}
          Mission de confiance
        </Button> */}
        {/* <div className={classes.divider} />
        <div className={classes.name}>
          <FormattedMessage id="hi" /> {firstName}
        </div> */}
        <div className={classes.middle}>
          {/* <Badge badgeContent={0} classes={{ badge: classes.badge }}>
            <Button
              startIcon={
                <div className={classes.iconWrap}>
                  <IconCart className={classes.btnIcon} />
                </div>}
              className={classes.iconbutton}
            //onClick={() => router.history.push('/carts')}
            >
              <FormattedMessage id="topbar.mycart" />
            </Button>
          </Badge>
          <Badge badgeContent={0} classes={{ badge: classes.badge }} style={{ marginLeft: 16 }}>
            <Button
              startIcon={
                <div className={classes.iconWrap}>
                  <IconChat className={classes.btnIcon} />
                </div>}
              className={classes.iconbutton}
              onClick={() => {
                //router.history.push('/messages')
              }}>
              <FormattedMessage id="topbar.mailbox" />
            </Button>
          </Badge> */}
        </div>
        <div>
          {/* <Tooltip title={`${firstName} ${lastName}`}>
            <IconButton aria-haspopup="true" onClick={handleMenu('anchorEl')}>
              <Avatar className={classes.avatar}>
                {String(firstName).charAt(0)}
                {String(lastName).charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={barState.anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleMenuClose('anchorEl')}
          >
            <MenuItem onClick={onProfile}>
              <FormattedMessage id="home.topbar.menu.view_profile" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={onSignOut}>
              <FormattedMessage id="home.topbar.menu.sign_out" />
            </MenuItem>
          </Menu> */}
          {/* <Button
            classes={{
              root: classes.clientBtn,
              label: classes.clientBtnLabel
            }}
            variant="contained"
            color="primary"
          >
            {'Contact Commercial'}
          </Button> */}

          <Button aria-haspopup="true" onClick={handleMenu('lng')}>
            <FormattedMessage id={"home.topbar.menu." + locale} />
          </Button>
          <Menu
            id="menu-lngbar"
            anchorEl={barState.lng}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openLng}
            onClose={handleMenuClose('lng')}
            PopoverClasses={{ root: classes.langRoot }}
          >
            <MenuItem onClick={handleLocaleChange('en')}>
              <ListItemIcon>
                <img alt="img" src={LOCALEIMAGES.en} width={32} style={{ marginRight: 10 }} />
              </ListItemIcon>
              <FormattedMessage id="home.topbar.menu.en" />
            </MenuItem>
            {/* <MenuItem>Help</MenuItem> */}
            <Divider />
            <MenuItem onClick={handleLocaleChange('fr')}>
              <ListItemIcon>
                <img alt="img" src={LOCALEIMAGES.fr} width={32} style={{ marginRight: 10 }} />
              </ListItemIcon>
              <FormattedMessage id="home.topbar.menu.fr" />
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
